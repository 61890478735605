export interface IColumn {
  name: string
  key: string | Function
  sortable?: boolean
  minWidth?: string
  order?: number
  isFixed?: boolean
  checked?: boolean
  filter?: IColumnFilter
}

export const OptionsMap: IOptionsMap = {
  'Denmark': { uom: 'Liters', currency: 'EUR', timezone: 'Europe/Copenhagen' },
  'Ireland': { uom: 'Liters', currency: 'EUR', timezone: 'Europe/Dublin' },
  'Northern Ireland': { uom: 'Liters', currency: 'GBP', timezone: 'Europe/Belfast' },
  'Sweden': { uom: 'Liters', currency: 'EUR', timezone: 'Europe/Helsinki' },
  'United Kingdom': { uom: 'Liters', currency: 'GBP', timezone: 'Europe/London' },
  'USA': { uom: 'Gallons(US)', currency: 'USD', timezone: 'America/Chicago' },
}

export const COUNTRY_OPTIONS: string[] = Object.keys(OptionsMap).filter(
  (value: string, index: number, self: string[]) => self.indexOf(value) === index,
)
interface IColumnFilter {
  type: 'string' | 'select' | 'date'
  options?: string[]
}

export const PULSES_VALUES = [
  '0.01',
  '0.1',
  '0.2',
  '0.5',
  '1',
  '2',
  '10',
  '38',
  '60',
  '119',
  '210',
  '1000',
  '2000',
  '10000',
]

const DEFAULT_COLUMNS: IColumn[] = [
  {
    name: 'Device Name/ID',
    key: 'deviceName',
    sortable: true,
    minWidth: '250px',
    isFixed: true,
    checked: true,
    order: 1,
    filter: {
      type: 'string',
    },
  },
]

export const USER_COLUMNS: IColumn[] = [
  ...DEFAULT_COLUMNS,
  {
    name: 'Account Name',
    key: 'accountName',
    minWidth: '250px',
    isFixed: false,
    checked: true,
    order: 2,
    filter: {
      type: 'string',
    },
  },
  {
    name: 'Location Country',
    key: 'locationCountry',
    minWidth: '250px',
    isFixed: false,
    checked: true,
    order: 3,
    filter: {
      type: 'select',
      options: COUNTRY_OPTIONS,
    },
  },
  {
    name: 'Install Date',
    key: 'deviceSettings.installDate',
    sortable: true,
    minWidth: '275px',
    isFixed: false,
    checked: true,
    order: 5,
    filter: {
      type: 'date',
    },
  },
  {
    name: 'Type',
    key: 'deviceSettings.type',
    sortable: true,
    isFixed: false,
    checked: false,
    order: 6,
    filter: {
      type: 'select',
      options: ['Hot', 'Cold'],
    },
  },
  {
    name: 'Battery %',
    key: 'batteryLevel',
    sortable: true,
    minWidth: '150px',
    isFixed: false,
    checked: true,
    order: 12,
  },
  {
    name: 'Signal',
    key: 'signalStrength',
    sortable: true,
    isFixed: false,
    checked: true,
    order: 13,
  },
  {
    name: 'Power',
    key: 'batteryStatus',
    sortable: true,
    minWidth: '150px',
    isFixed: false,
    checked: true,
    order: 15,
    filter: {
      type: 'select',
      options: ['Plugged', 'Internal Battery'],
    },
  },
  {
    name: 'Status',
    key: 'deviceStatus',
    sortable: true,
    minWidth: '150px',
    isFixed: false,
    checked: true,
    order: 16,
    filter: {
      type: 'select',
      options: ['Online', 'Offline'],
    },
  },
  {
    name: 'DLID',
    key: 'dlId',
    minWidth: '250px',
    isFixed: false,
    checked: false,
    order: 20,
    filter: {
      type: 'string',
    },
  },
  {
    name: 'UUID',
    key: 'dUUID',
    minWidth: '250px',
    isFixed: false,
    checked: false,
    order: 20,
    filter: {
      type: 'string',
    },
  },
]

export const COLUMNS: IColumn[] = [
  ...USER_COLUMNS,
  {
    name: 'Sub Entity',
    key: 'companyAlias',
    minWidth: '250px',
    isFixed: false,
    checked: false,
    order: 4,
    filter: {
      type: 'string',
    },
  },
  {
    name: '30 Day Report',
    key: 'deviceSettings.report30Day',
    sortable: true,
    minWidth: '200px',
    isFixed: false,
    checked: false,
    order: 7,
    filter: {
      type: 'select',
      options: ['Yes', 'No'],
    },
  },
  {
    name: 'Pulses',
    key: 'deviceSettings.pulsesPerLiter',
    sortable: true,
    isFixed: false,
    checked: true,
    order: 8,
    filter: {
      type: 'select',
      options: PULSES_VALUES,
    },
  },
  {
    name: 'Firmware',
    key: 'firmware',
    sortable: true,
    isFixed: false,
    checked: true,
    order: 9,
    filter: {
      type: 'string',
    },
  },
  {
    name: 'ICCID',
    key: 'ICCID',
    sortable: true,
    isFixed: false,
    checked: false,
    order: 10,
    filter: {
      type: 'string',
    },
  },
  {
    name: 'MVNO',
    key: 'mvno',
    sortable: true,
    isFixed: false,
    checked: false,
    order: 11,
    filter: {
      type: 'select',
      options: ['-', 'Emnify', 'EsEye', 'Onomondo', 'Simpl'],
    },
  },
  {
    name: 'Network',
    key: 'networkName',
    sortable: true,
    minWidth: '150px',
    isFixed: false,
    checked: true,
    order: 14,
    filter: {
      type: 'string',
    },
  },
  {
    name: 'State',
    key: 'deviceSettings.active',
    sortable: true,
    minWidth: '150px',
    isFixed: false,
    checked: true,
    order: 17,
    filter: {
      type: 'select',
      options: ['Active', 'Inactive'],
    },
  },
  {
    name: 'CSQ',
    key: 'signal',
    sortable: true,
    isFixed: false,
    checked: false,
    order: 19,
  },
  {
    name: 'Vendor',
    key: 'deviceVendorName',
    minWidth: '200px',
    sortable: true,
    isFixed: false,
    checked: true,
    order: 18,
    filter: {
      type: 'select',
      options: ['SMARTFLOW.v3', 'SMARTFLOW.P', 'SMARTFLOW.M'],
    },
  },
]

export const SECTOR_TYPES_OPTIONS: string[] = [
  'Bar',
  'Hotel',
  'Restaurant',
  'Office',
  'Service station',
  'Nursing Home',
  'Gym',
  'Manufacturing',
  'Shopping Center',
  'Cinema',
  'Shop',
  'Construction Site',
  'Pool',
  'Student Accommodation',
  'Real Estate',
  'Industrial',
  'Residential',
  'Rain Water Harvester',
]

export const FIRMWARE_OPTIONS: string[] = [
  'SM_V3_035a_RC25_Apr_12_2024.bin',
  'SM_V3_035a_RC28c_Jul_5_2024.bin',
  'SM_V3_036.0.1.bin',
]

// export const CurrencyCountryMap: {[key: string]: string} =  {
// 	'Ireland': 'EUR',
//   'United Kingdom': 'GBP',
//   'Northern Ireland': 'GBP',
//   'USA': 'USD'
// }

// export const TimezoneCountryMap: {[key: string]: string} =  {
// 	'Ireland': 'Europe/Dublin',
//   'United Kingdom': 'Europe/London',
//   'Northern Ireland': 'Europe/Belfast',
//   'USA': 'America/Chicago'
// }

// type CountryKeys = keyof typeof CurrencyCountryMap;
// type CurrencyValue = typeof CurrencyCountryMap[CountryKeys];

// export const COUNTRY_OPTIONS: string[] = Object.keys(CurrencyCountryMap).filter((value: string, index: number, self:string[]) => self.indexOf(value) === index)
// export const CURRENCY_OPTIONS: string[] = Object.values(CurrencyCountryMap).filter((value: string, index: number, self:string[]) => self.indexOf(value) === index)

// export const UOMCountryMap: {[key: string]: string} =  {
// 	'Ireland': 'Liters',
//   'United Kingdom': 'Liters',
//   'Northern Ireland': 'Liters',
//   'USA': 'Gallons(US)'
// }

interface IOptionsMap {
  [key: string]: {
    uom: string
    currency: string
    timezone: string
  }
}

export const UOM_OPTIONS: string[] = Object.values(OptionsMap)
  .map((value) => value.uom)
  .filter((uom: string, index: number, self: string[]) => self.indexOf(uom) === index)

export const CURRENCY_OPTIONS: string[] = Object.values(OptionsMap)
  .map((value) => value.currency)
  .filter((currency: string, index: number, self: string[]) => self.indexOf(currency) === index)

export const UPLOAD_FREQ_MINS: string[] = ['60', '120', '180', '240', '360', '720', '1080', '1440']

export const CustomerJourneyDeviceColumns: IColumn[] = [
  ...DEFAULT_COLUMNS,
  {
    name: 'Install Date',
    key: 'deviceSettings.installDate',
    sortable: true,
    minWidth: '270px',
    checked: true,
    order: 2,
    filter: {
      type: 'date',
    },
  },
  {
    name: '30 Day Report',
    key: 'deviceSettings.report30Day',
    sortable: true,
    minWidth: '150px',
    checked: true,
    order: 3,
    filter: {
      type: 'select',
      options: ['Yes', 'No'],
    },
  },
  {
    name: 'ESG Report',
    key: 'deviceSettings.esgReport',
    sortable: true,
    // minWidth: '200px',
    checked: true,
    order: 4,
    filter: {
      type: 'select',
      options: ['Yes', 'No'],
    },
  },
  {
    name: 'Sustainability Report',
    key: 'deviceSettings.sustainabilityReport',
    sortable: true,
    minWidth: '200px',
    checked: true,
    order: 5,
    filter: {
      type: 'select',
      options: ['Yes', 'No'],
    },
  },
  {
    name: 'Set Up Payment received',
    key: 'deviceSettings.setUpPayment',
    sortable: true,
    minWidth: '220px',
    checked: true,
    order: 6,
    filter: {
      type: 'select',
      options: ['Yes', 'No'],
    },
  },
  {
    name: 'Device Renewal over Due',
    key: 'deviceSettings.renewalOverdueStatus',
    sortable: true,
    minWidth: '215px',
    checked: true,
    order: 8,
    filter: {
      type: 'select',
      options: ['Yes', 'No'],
    },
  },
  {
    name: 'Comments',
    key: 'lastComment',
    checked: true,
    minWidth: '250px',
    order: 9,
    filter: {
      type: 'string',
    },
  },
]

export const CustomerJourneyLocationColumns: IColumn[] = [
  {
    name: 'Location Name',
    key: 'name',
    sortable: true,
    minWidth: '250px',
    isFixed: true,
    checked: true,
    order: 1,
    filter: {
      type: 'string',
    },
  },
  {
    name: 'On Boarding Date',
    key: 'location.onboardDate',
    sortable: true,
    minWidth: '170px',
    checked: true,
    order: 2,
    filter: {
      type: 'date',
    },
  },
  {
    name: 'Comments',
    key: 'location.lastComment',
    checked: true,
    minWidth: '250px',
    order: 3,
    filter: {
      type: 'string',
    },
  },
]
export const CustomerJourneyAccountColumns: IColumn[] = [
  {
    name: 'Account Name',
    key: 'name',
    sortable: true,
    minWidth: '250px',
    isFixed: true,
    checked: true,
    order: 1,
    filter: {
      type: 'string',
    },
  },
  {
    name: 'Executive Summary',
    key: 'reports.exSummaryReport',
    sortable: true,
    minWidth: '220px',
    checked: true,
    order: 7,
    filter: {
      type: 'select',
      options: ['Up to date', 'Due', 'Over due'],
    },
  },
  {
    name: 'Comments',
    key: 'account.lastComment',
    checked: true,
    minWidth: '250px',
    order: 8,
    filter: {
      type: 'string',
    },
  },
]
